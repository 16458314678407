const HEADERS = {
  ID: {
    name: 'id',
    text: 'ID',
    value: 'id',
    align: 'left',
    sortable: false,
  },
  HOSTNAME: {
    name: 'hostname',
    text: 'Терминал',
    value: 'hostname',
    align: 'center',
    sortable: false,
  },
  VERSION: {
    name: 'version',
    text: 'Версия',
    value: 'version',
    align: 'center',
    sortable: false,
  },
  TAGS: {
    name: 'tags',
    text: 'Тэги',
    value: 'tags',
    align: 'center',
    sortable: false,
  },
  LICENSE: {
    name: 'license',
    text: 'Лицензия',
    value: 'license',
    align: 'center',
    sortable: false,
  },
  ACTIONS: {
    text: 'Действия',
    value: 'actions',
    align: 'center',
    sortable: false,
  },
};

export default HEADERS;
