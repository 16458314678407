<template lang="pug">
  v-card(themes="points-white")
    v-card-title
      v-row
        v-col(cols="12" md="4" align-self="center")
          span Терминалы
        v-spacer
        v-col.text-right(cols="12" md="4")
          //- v-btn.white--text(
          //-   prepend-icon="fa-plus"
          //-   color="blue"
          //-   :disabled="$root.isDisabled"
          //-   @click="showVersionChange = true"
          //- ) 
          //-   v-icon.pr-2(small) fa-plus
          //-   span Добавить

    v-card-text
      v-row
        v-col(lg="12" sm="12" xs="1")
          v-data-table(
              :headers="headers"
              :items="items"
              :items-per-page="-1"
              hide-default-footer
              hide-default-header
              no-data-text="T"
              no-result-text="D"
            )
              template(v-slot:header="{ props: { headers } }")
                thead
                  tr
                    //- th
                    th(
                      v-for="item in headers"
                      :style="headerItemAlign(item.value)"
                    ) {{item.text}}
              template(v-slot:body="{ items }")
                tbody
                  tr(v-for="(item, idx) in items" :key="idx" style="width:100%;")
                    //- td.cell--right
                    //-   v-tooltip(
                    //-     right
                    //-     open
                    //-     v-if="checkboxDisabled(item)"
                    //-   )
                    //-     template(#activator="{ on }")
                    //-       div(v-on="on")
                    //-         v-checkbox(
                    //-           disabled
                    //-         )
                    //-     span Вы можете редактировать только терминалы одной организации и одной версии
                    //-   v-checkbox.mt-0.pa-0(
                    //-     v-else
                    //-     v-model="chosenTerminals"
                    //-     :value="item"
                    //-   )

                    td(align="center") {{item.id}}
                    td(align="left") {{item.hostname}}
                    td(align="center") {{item.version}}
                    td(align="left")
                      tags-controls(
                        v-model="item.tags"
                        :terminalId="item.id"
                      )
                    td(align="left") {{item.license}}
                    td.pa-2(align="right")
                      v-tooltip(left)
                        template(v-slot:activator="{ on }")
                          v-btn(
                            v-on="on"
                            fab
                            small
                            variant="light"
                            :to="`edit/?ids=${item.id}&verId=${getIdVersionByName(item.version)}`"
                            prependIcon="fa-edit"
                            elevation="0"
                          )
                            v-icon(
                              color="blue"
                              small
                            ) fa-pencil-alt
                        span Редактировать

        v-col.text-center(v-if="!$root.isLoading && !items.length")
          span.blue--text Нет терминалов по данным фильтрам
        v-col(cols="12" v-show="$root.isLoading('terminals_list')")
          spinner
        v-col(cols="12")
          v-btn.white--text.absoluteBtn(
            v-show="chosenTerminals.length"
            :to="editPath"
            prependIcon="fa-pencil-alt"
            color="blue"
          ) Редактировать терминалы

    version-change(
      v-model="showVersionChange"
      :versions="versions"
      @change="onChangeVersion"
    )
</template>

<script>
import HEADERS from './headers';
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'lodash';

export default {
  name: 'TerminalsList',

  components: {
    TagsControls: () =>
      import('@/components/terminals/tags-controls/index.vue'),
    VersionChange: () =>
      import('@/components/terminals/version-change-modal/index.vue'),
    Spinner: () => import('@/components/Spinner.vue'),
  },

  props: {
    queryList: {
      type: Function,
      default: () => null,
    },
  },

  data() {
    return {
      headers: [],
      bottom: false,
      chosenTerminals: [],
      showVersionChange: false,
    };
  },

  computed: {
    ...mapGetters('TERMINALS_LIST', ['items', 'isAllViews']),
    ...mapGetters('TERMINALS_VERSIONS', ['versions', 'getIdVersionByName']),

    editPath() {
      if (this.chosenTerminals.length === 0) {
        return;
      }

      const ids = this.chosenTerminals.map(terminal => terminal.id).join(',');
      const verId = this.getIdVersionByName(this.chosenTerminals[0].version);

      return `edit/?ids=${ids}&verId=${verId}`;
    },

    headerItemAlign: () => name => {
      let align = 'center';

      if (name === 'actions') {
        align = 'right';
      } else if (name === 'hostname' || name === 'license' || name === 'tags') {
        align = 'left';
      }

      return { 'text-align': align };
    },
  },

  watch: {
    bottom(bottom) {
      if (bottom && !this.isAllViews) {
        this.query();
      }
    },
  },

  created() {
    window.addEventListener('scroll', () => {
      this.bottom = this.bottomVisible();
    });
  },

  mounted() {
    this.headers = [
      HEADERS.ID,
      HEADERS.HOSTNAME,
      HEADERS.VERSION,
      HEADERS.TAGS,
      HEADERS.LICENSE,
      HEADERS.ACTIONS,
    ];
  },

  methods: {
    ...mapActions('TERMINAL_CREATE', ['getSchemas']),

    query: debounce.call(
      this,
      function () {
        return this.queryList();
      },
      300,
    ),

    onChangeVersion(id) {
      this.getSchemas(id).then(() => {
        this.$router.push({ name: 'create' });
      });
    },

    checkboxDisabled(terminal) {
      if (terminal.isOpenpoint) return true;
      if (!this.chosenTerminals.length) return false;
      if (
        terminal.version === this.chosenTerminals[0].version &&
        terminal.orgId === this.chosenTerminals[0].orgId
      )
        return false;
      else return true;
    },

    bottomVisible() {
      const scrollY = window.scrollY;
      const visible = document.documentElement.clientHeight;
      const pageHeight = document.documentElement.scrollHeight;
      const bottomOfPage = visible + scrollY >= pageHeight;
      return bottomOfPage || pageHeight <= visible;
    },
  },
};
</script>

<style lang="sass">
.absoluteBtn
  position: absolute
  left: 50%
  bottom: 70px
  transform: translateX(-50%)

  background-color: #0088D6
  position: fixed
  bottom: 70px
  z-index: 99999999
</style>
